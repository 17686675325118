import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { css, ClassNames } from '@emotion/core'
import Link from '../components/Link'
import Text from '../components/Text'
import Modal from 'react-modal'
import theme from '../constants/theme'
import LogoNav from '../images/nav/LogoNav.svg'

import image1 from '../images/2021pd/image1.png'
import image2 from '../images/2021pd/image2.png'
import image3 from '../images/2021pd/image3.png'
import image4 from '../images/2021pd/image4.png'
import FrosinaStojanovska from '../images/2021pd/FrosinaStojanovska-Pocuca.png'
import ElisaRomeo from '../images/2021pd/ElisaRomeo.jpg'
import NataliaPshenitsyna from '../images/2021pd/NataliaPshenitsyna.png'
import PD2022 from '../images/PD2022.jpeg'
import PD20231 from '../images/OCMA2023.jpg'
import PD20232 from '../images/jeffreypeck.jpg'

import OCMAPDFall20231 from '../images/OCMA2023_pd.jpg'
import OCMAPDFall20232 from '../images/Joshua.jpeg'
import OCMAPDFall20233 from '../images/Maksim.jpeg'
import OCMAPDWinter2024 from '../images/Robb-Caporicci.jpg'
import OCMAPDfall2024 from '../images/Stephanie_McKean.jpg'


import { FaCaretDown, FaFacebookF, FaTwitter } from 'react-icons/fa'
import { useSpring, animated } from 'react-spring/web.cjs'
import { useMeasure, usePrevious } from '../constants/helpers'
// import SocialLink from "./FeatLink";

Modal.setAppElement(`#___gatsby`)

const navLinks = [
  {
    titleID: 'Home',
    to: '/',
    hideOnMobile: true,
  },
  {
    titleID: 'About',
    to: '/about',
    subnav: [
      {
        titleID: 'Our Purpose',
        to: '/about#purpose',
      },
      {
        titleID: 'Executives',
        to: '/about#executives',
      },
      {
        titleID: 'Member Colleges',
        to: '/about#colleges',
      },
    ],
  },
  {
    titleID: 'Events',
    to: '/events',
    subnav: [
      {
        titleID: 'Conference 2024',
        to: '/annual-conference-2024',
      },
      {
        titleID: 'All Events',
        to: '/events',
      },
    ],
  },
  {
    titleID: 'Affiliates',
    to: '/affiliates',
  },
  {
    titleID: 'Contact',
    to: '/contact',
  },
]

const LogoLink = styled(Link)`
  margin: 0 12px;
`

const Wrap = styled.div`
  background-color: ${(props) =>
    !props.isTop ? theme.colors.white : 'transparent'};
  color: ${theme.colors.black};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${theme.nav.height};
  width: 100%;
  position: fixed;
  z-index: ${theme.z.mobileNavMenu};
  transition: 0.2s ease-in-out all;
  box-shadow: ${(props) =>
    !props.isTop ? '2px 2px 2px rgba(0,0,0,0.15)' : 'none'};

  & > * {
    user-select: none;
  }
`

const Logo = styled.img`
  max-width: 100%;
  position: relative;
  z-index: ${theme.z.mobileNavMenuToggle};
`

const MainLinks = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  display: inline-flex;
  justify-content: flex-end;

  ${theme.mq[1]} {
    display: none;
  }
`

const MainLinksItem = styled(Link)`
  color: ${theme.colors.black};
  text-align: center;
  position: relative;
  height: 100%;
  ${theme.flexCenter()};
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: bold;

  &:hover,
  &:active,
  &:focus {
    color: ${theme.colors.black};
  }
`

const SubnavItemDropdown = styled.div`
  position: absolute;
  top: 100%;
  background: ${theme.colors.white};
  text-align: left;
  display: none;
  width: 200px;
  left: -${theme.margin.small};
`

const MobileNavLinkText = (props) => (
  <Text
    pFeat
    css={css`
      text-transform: uppercase;
      margin: 0;
    `}
    {...props}
  />
)

const MobileHamburgerMenuButton = ({ open, set }) => {
  const barStyles = css`
    transition: 0.12s ${open ? `ease-in-out` : `ease-out`} transform;
  `

  return (
    <div
      onClick={() => set(!open)}
      css={css`
        position: absolute;
        right: 0;
        margin: 0 ${theme.margin.small};
        cursor: pointer;
        display: none;

        ${theme.mq[1]} {
          display: block;
        }

        & > div {
          width: 24px;
          height: 3px;
          background-color: ${theme.colors.black};
          margin: 5px 0;
        }
      `}
    >
      <div
        css={css`
          ${barStyles};
          transform: ${open ? `rotate(45deg)` : `rotate(0)`};
          transform-origin: top left;
        `}
      />
      <div
        css={css`
          ${barStyles};
          opacity: ${open ? 0 : 1};
          transition-delay: ${!open ? `0.05s` : `0s`};
          transition-property: opacity;
        `}
      />
      <div
        css={css`
          ${barStyles};
          transform: ${open ? `rotate(-45deg)` : `rotate(0)`};
          transform-origin: bottom left;
        `}
      />
    </div>
  )
}

const MobileNavMenuItem = ({ title, url, subnav }) => {
  const [isOpen, setOpen] = React.useState(false)
  const previous = usePrevious(isOpen)
  const [bindRef, { height: viewHeight }] = useMeasure()
  const { height, opacity, transform, ppp } = useSpring({
    from: {
      height: 0,
      opacity: 0,
      transform: 'translate3d(20px,0,0)',
      ppp: `rotate(0deg)`,
    },
    to: {
      height: isOpen ? viewHeight : 0,
      opacity: isOpen ? 1 : 0,
      transform: `translate3d(${isOpen ? 0 : 20}px,0,0)`,
      ppp: `rotate(${isOpen ? 180 : 0}deg)`,
    },
  })

  useEffect(() => {
    document.querySelectorAll('img').forEach((item) => {
      var src = item.getAttribute('src')
      src = src.split('/').pop()
      console.log(src)
      if (src === 'image1.png') {
        item.setAttribute('src', image1)
      } else if (src === 'image2.png') {
        item.setAttribute('src', image2)
      } else if (src === 'image3.png') {
        item.setAttribute('src', image3)
      } else if (src === 'image4.png') {
        item.setAttribute('src', image4)
      } else if (src === 'ElisaRomeo.jpg') {
        item.setAttribute('src', ElisaRomeo)
      } else if (src === 'FrosinaStojanovska-Pocuca.png') {
        item.setAttribute('src', FrosinaStojanovska)
      } else if (src === 'pasted-image-0-259x300.png') {
        item.setAttribute('src', NataliaPshenitsyna)
      } else if (src === 'unnamed-1-300x300.jpeg') {
        item.setAttribute('src', PD2022)  
      } else if (src === 'OCMA2023.jpg') {
        item.setAttribute('src', PD20231)
      } else if (src === 'jeffreypeck.jpg') {
        item.setAttribute('src', PD20232)
      } else if (src === 'OCMA2023_pd.jpg') {
        item.setAttribute('src', OCMAPDFall20231)
      } else if (src === 'Joshua.jpeg') {
        item.setAttribute('src', OCMAPDFall20232)
      } else if (src === 'Maksim.jpeg') {
        item.setAttribute('src', OCMAPDFall20233)
      } else if (src === 'Robb-Caporicci.jpg') {
      item.setAttribute('src', OCMAPDWinter2024)
      } else if (src === 'Stephanie_McKean.jpg') {
      item.setAttribute('src', OCMAPDfall2024)
      }
        
    })
  })

  if (!subnav) {
    return (
      <Link to={url}>
        <MobileNavLinkText>{title}</MobileNavLinkText>
      </Link>
    )
  } else {
    return (
      <div>
        <div
          onClick={() => {
            setOpen(!isOpen)
            console.log('isOpen', isOpen)
          }}
          css={css`
            display: flex;
            align-items: center;
            cursor: pointer;
          `}
        >
          <MobileNavLinkText>{title}</MobileNavLinkText>
          <div
            css={css`
              margin-left: ${theme.margin.small};
            `}
          >
            <animated.div style={{ transform: ppp }}>
              <FaCaretDown
                css={css`
                  color: ${theme.colors.red};
                  display: inline-block;
                  font-size: 1.2em;
                `}
              />
            </animated.div>
          </div>
        </div>
        <animated.div
          style={{
            opacity,
            height: isOpen && previous === isOpen ? 'auto' : height,
          }}
          css={css`
            padding-left: ${theme.margin.small};
            position: relative;
            overflow: hidden;
          `}
        >
          <animated.div {...bindRef} style={{ transform }}>
            {subnav.map((subnavLink, i) => (
              <Link
                key={`mobileSubnavLink_${subnavLink.titleID}_${i}`}
                to={subnavLink.to}
              >
                <MobileNavLinkText
                  css={css`
                    padding: 8px 0;
                  `}
                >
                  {subnavLink.titleID}
                </MobileNavLinkText>
              </Link>
            ))}
          </animated.div>
        </animated.div>
      </div>
    )
  }
}

const SocialLink = styled(Link)`
  &,
  &:hover,
  &:focus {
    font-size: 1em;
    color: ${theme.colors.black};
    margin-right: ${theme.margin.small};
  }

  &:last-of-type {
    margin: 0;
  }
`

export default function NavBar({ openModal }) {
  const [mobileNavOpen, setMobileNavState] = React.useState(false)

  return (
    <Wrap>
      <LogoLink to="/">
        <Logo src={LogoNav} alt="Logo" />
      </LogoLink>
      <ClassNames>
        {({ css }) => (
          <>
            <MainLinks>
              {navLinks
                .filter((link) => !link.hideOnMobile)
                .map((link) => (
                  <div
                    key={`navbarLink_${link.titleID}_${Math.random()}`}
                    css={css`
                      ${theme.flexCenter()};
                      height: 100%;
                      position: relative;
                      margin: 12px ${theme.margin.small};
                      cursor: pointer;

                      &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 0;
                        height: 5px;
                        background-color: currentColor;
                        width: 120%;
                        min-width: 80px;
                        transform-origin: 0;
                        transition: 0.2s ease-in-out transform;
                        left: 50%;
                        transform: translateX(-50%) scaleX(0);
                      }

                      &:hover:before {
                        transform: translateX(-50%) scaleX(1);
                      }
                    `}
                  >
                    {!link.subnav ? (
                      <MainLinksItem to={link.to}>{link.titleID}</MainLinksItem>
                    ) : (
                      <div
                        css={css`
                          ${theme.flexCenter()};
                          position: relative;
                          height: 100%;

                          &:hover ${SubnavItemDropdown} {
                            display: inline-block;
                          }
                        `}
                      >
                        <MainLinksItem to={link.to}>
                          {link.titleID}
                        </MainLinksItem>
                        <FaCaretDown
                          css={css`
                            color: ${theme.colors.red};
                            display: inline-block;
                            font-size: 1.2em;
                            margin-left: ${theme.margin.small};
                          `}
                        />
                        <SubnavItemDropdown>
                          {link.subnav.map((subnavItem, i) => (
                            <Link
                              to={subnavItem.to}
                              key={`subnavItem_${link.titleID}_${i}`}
                            >
                              <div
                                css={css`
                                  padding: 6px 12px;
                                  color: ${theme.colors.black};
                                  transition: 0.12s ease-in-out all;

                                  &:hover {
                                    background-color: ${theme.colors.red};
                                    color: ${theme.colors.white};
                                  }
                                `}
                              >
                                {subnavItem.titleID}
                              </div>
                            </Link>
                          ))}
                        </SubnavItemDropdown>
                      </div>
                    )}
                  </div>
                ))}

              <div
                css={css`
                  ${theme.flexCenter()};
                  color: ${theme.colors.black};
                  position: relative;
                  top: -3px;
                  margin: 0 16px 0 32px;
                `}
              >
                <SocialLink to="http://twitter.com/theocma">
                  <FaTwitter />
                </SocialLink>
                <SocialLink to="https://www.facebook.com/theOCMA/">
                  <FaFacebookF />
                </SocialLink>
              </div>

              <div
                onClick={openModal}
                css={css`
                  /* font-weight: bold; */
                  padding: 8px 16px;
                  height: 100%;
                  background: ${theme.colors.blueDark};
                  color: ${theme.colors.white};
                  ${theme.flexCenter()};

                  &:hover {
                    cursor: pointer;
                  }
                `}
              >
                SUBSCRIBE
              </div>
            </MainLinks>
            <div
              css={css`
                position: absolute;
                left: ${mobileNavOpen ? `0%` : `100%`};
                top: calc(${theme.nav.height} - 1px);
                min-height: calc(100vh - ${theme.nav.height} + 1px);
                transition: 0.2s ease-in-out left;
                width: 100%;
                background: ${theme.colors.white};
                display: flex;
                flex-direction: column;

                padding: ${theme.margin.mid} ${theme.margin.small};

                & > * {
                  margin-bottom: ${theme.margin.small};
                }
              `}
            >
              {navLinks.map((link, i) => (
                <MobileNavMenuItem
                  key={`mobileNavLink_${link.titleID}_${i}`}
                  title={link.titleID}
                  url={link.to}
                  subnav={link.subnav && link.subnav}
                />
              ))}
              <div
                css={css`
                  ${theme.flexCenter()};
                  color: ${theme.colors.black};
                  position: relative;
                  top: -3px;
                `}
              >
                <SocialLink to="http://twitter.com/theocma">
                  <FaTwitter />
                </SocialLink>
                <SocialLink to="https://www.facebook.com/theOCMA/">
                  <FaFacebookF />
                </SocialLink>
              </div>
              <div
                onClick={openModal}
                css={css`
                  padding: 8px 16px;
                  text-align: center;
                  background-color: ${theme.colors.blueDark};
                  color: ${theme.colors.white};

                  &:hover {
                    cursor: pointer;
                  }
                `}
              >
                SUBSCRIBE
              </div>
            </div>
            <MobileHamburgerMenuButton
              open={mobileNavOpen}
              set={setMobileNavState}
            />
          </>
        )}
      </ClassNames>
    </Wrap>
  )
}
